import axios from '@/plugins/axios.js'

// 搜索（企业）
export const enterpriseSearch = (params) => axios.post('/enterprise/search', params)

// 新增企业
export const enterpriseAdd = (params) => axios.post('/enterprise/add', params)

// 编辑企业
export const enterpriseEdit = (params) => axios.post('/enterprise/edit', params)

// 导出（企业）
export const enterpriseExport = (params) =>
  axios.post('/enterprise/export', params, { responseType: 'blob' })

// 查询（企业/渠道）
export const enterpriseQuery = (params) => axios.get('/enterprise/query', { params })

// 删除企业/渠道
export const enterpriseRemove = (params) => axios.delete('/enterprise/remove', { params })

// 冻结
export const enterpriseFrozen = (params) => axios.post('/enterprise/frozen', {}, { params })

// 解封
export const enterpriseUnfreeze = (params) => axios.post('/enterprise/unfreeze', {}, { params })

// 搜索（企业）
export const channelSearch = (params) => axios.post('/channel/search', params)

// 新增渠道
export const channelAdd = (params) => axios.post('/channel/add', params)

// 编辑渠道
export const channelEdit = (params) => axios.post('/channel/edit', params)

// 导出（渠道）
export const channelExport = (params) =>
  axios.post('/channel/export', params, { responseType: 'blob' })

// 发送邮件（企业）
export const enterpriseSend = (params) => axios.post('/enterpriseManagement/sendEmail', params)
