<template>
  <div class='page'>
    <div class='page-main'>
      <el-form
        class='form-max-900'
        ref='form'
        :model='form'
        :rules='rules'
        label-width='auto'
      >
        <div class='send-email-templates'>
          {{ $t('enterpriseSendEmail.template') }}
        </div>
        <el-form-item class='page-tools-right'>
          <el-select v-model='form.language' size='medium'>
            <el-option
              v-for='template in templates'
              :key='template.value'
              :label='template.label'
              :value='template.value'
            ></el-option>
          </el-select>
        </el-form-item>
        <div class='send-email-title'>
          {{ $t('enterpriseSendEmail.title') }}
        </div>
        <el-form-item prop='title'>
          <el-input
            v-model='form.title'
            :placeholder='titlePlaceholder'
            :maxlength='500'
            type='textarea'
            :rows='1'
          ></el-input>
        </el-form-item>
        <div class='send-email-body'>{{ $t('enterpriseSendEmail.body') }}</div>
        <el-form-item prop='body'>
          <el-input
            v-model='form.body'
            :placeholder='bodyPlaceholder'
            :maxlength='10000'
            type='textarea'
            :rows='10'
          ></el-input>
        </el-form-item>
        <el-form-item class='footer-btns'>
          <el-button class='footer-btn' @click='cancel'>{{
            $t('cancel')
          }}</el-button>
          <el-button class='footer-btn' type='primary' @click='submit'>{{
            $t('enterpriseSendEmail.send')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { enterpriseSend } from '@/api/enterprise.js'

export default {
  name: 'SendEmail',
  components: {},
  created() {
    this.$route.query.tableSelection.forEach((item) =>
      this.form.ids.push(item.id)
    )
  },
  data() {
    return {
      form: {
        ids: [],
        language: 'cn',
        title: '',
        parameters: {
          message: '',
          dearBla: '',
        },
      },
      templates: [
        { label: this.$t('enterpriseSendEmail.chinese'), value: 'cn' },
        { label: this.$t('enterpriseSendEmail.english'), value: 'en' },
      ],
      rules: {
        title: {
          required: true,
          message: this.$t('requireTips'),
          trigger: 'blur',
        },
        body: {
          required: true,
          message: this.$t('requireTips'),
          trigger: 'blur',
        },
      },
      titlePlaceholder: this.$t('enterpriseSendEmail.titlePla'),
      bodyPlaceholder: this.$t('enterpriseSendEmail.bodyPla'),
      tableSelection: [],
    }
  },
  mounted() {},
  methods: {
    // 发送
    submit() {
      this.form.parameters.dearBla = this.form.language == 'cn' ?
        '尊敬的ALE设备管理平台用户，您好！' : 'Dear EDM user,'
      this.form.parameters.message = this.form.body
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            ids: this.form.ids,
            language: this.form.language,
            title: this.form.title,
            parameters: this.form.parameters,
          }
          enterpriseSend(params)
            .then((res) => {
              this.$message.success(res.data.message)
              this.cancel()
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消
    cancel() {
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" scoped>
.page-main {
  padding: 2%
}
.send-email-templates,
.send-email-title,
.send-email-body {
  padding-bottom: 1%
}
</style>